<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ຂໍ້ມູນສະສາງພັດສະດຸ
                    <span class="countTotal">({{ count }})</span>
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns" style="display: flex;flex-wrap: wrap">
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາເລກບິນ :
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
                placeholder="ເລກບິນ.."
            />
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຈາກວັນທີ ສະສາງພັດສະດຸ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຫາວັນທີ ສະສາງພັດສະດຸ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                v-model="end_date"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>

          </div>
        </div>

        <div class="column is-3" style="position: relative; margin-top: 20px">
          <div style="display: flex">
            <vs-button success @click="onImport" :loading="isBtnLoading">
              <span style="margin-right: 8px; !important;"><i class="fal fa-file-spreadsheet"></i></span>
              Import
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດບິນ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ວັນທີສະສາງພັດສະດຸ</vs-th>
            <vs-th>ຈຳນວນເງິນຄ່າສາງ</vs-th>
            <vs-th>ລາຄາຕົ້ນທາງ</vs-th>
            <vs-th>ລາຄາ CDC</vs-th>
            <vs-th>ລາຄາ COD</vs-th>
            <vs-th>ລູກຄ້າຕົ້ນທາງ</vs-th>
            <vs-th>ລູກຄ້າປາຍທາງ</vs-th>
            <vs-th>ການເຄື່ອນໄຫວສະຖານະບິນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="index"
                 :data="item"
                 v-for="(item, index) in $vs.getPage(ListItems, page, max)">
            <vs-td style="text-align: right; width: 300px">
            </vs-td>
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>{{ item.shipment.shipment_number }}</vs-td>
            <vs-td>{{ item.shipment.start_branch.province.prefix }}-{{ item.shipment.start_branch.name }}
            </vs-td>
            <vs-td>{{ item.shipment.end_branch.province.prefix }}-{{ item.shipment.end_branch.name }}
            </vs-td>
            <vs-td>{{ formatDate(item.shipment.bill_cleared_date) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.shipment.total_rental_price) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.origin_freight_price) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.cdc_price) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.shipment.total_price) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment.sender_customer.name) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment.receiver_customer.name) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.latest_tracking) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment.remark) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <ImportShipmentClearedOrders @close="close" @success="onImportSuccess"/>
      </template>
    </ModalAdd>

  </div>
</template>
<script>
import Loading from "@/views/Loading";
import pagination from "@/Plugins/Paginations/CusorPagination";
import DatePicker from "vue2-datepicker";
import * as helper from "@/Helpers/GetValue";
import ImportShipmentClearedOrders from "@/views/SuperAdmin/ShipmentCleared/Modal/ImportShipmentClearedOrders.vue"
import moment from "moment";
import helpers from "../../../Helpers";

export default {
  computed: {
    helpers() {
      return helpers
    }
  },
  components: {
    Loading,
    pagination,
    DatePicker,
    ImportShipmentClearedOrders
  },


  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    isBtnLoading: false,

    ListItems: [],
    id: null,
    // for delete
    itemId: null,
    start_date: moment(new Date()).subtract(2, 'days')
        .format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),


    searchStatus: "",
    searchType: "",
    searchItem: "",

    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function (value) {
      if (value) {
        this.FetchData();
      }
    },
    'end_date': function (value) {
      if (value) {
        this.FetchData();
      }
    },
  },

  methods: {
    nullSafe(instance, func) {
      try {
        return func(instance);
      } catch (_) {
        return undefined
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },
    onCreate() {
      this.$store.commit("modalAdd_State", true);
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItem().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.pagination = res.data;
            this.ListItems = res.data.data.map(item => ({
              origin_freight_price: item.shipment.shipment_pay_type.includes('origin_freight_fees') ? item.shipment.total_freight : '---',
              cdc_price: item.shipment.shipment_pay_type.includes('destination_freight_fees') ? item.shipment.total_freight : '---',
              ...item,
            }))
            this.pagination = res.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      }).finally(() => {
        this.fetchCount();
      });
    },
    fetchItem(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-cleared', {
        headers: {
          Locale: 'lo'
        },
        params: {
          is_only_count: options.isCount || false,
          start_date: this.start_date,
          end_date: this.end_date,
          query: this.searchItem,
          limit: 12,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    onImport() {
      this.$store.commit("modalAdd_State", true);
    },
    onImportSuccess(data) {
      if(data) {
        this.$notification.OpenNotification_ImportFile_Result_Success('top-right', 'primary', 3500);
        this.FetchData();
      }
    },
    fetchCount() {
      this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-cleared', {
        headers: {
          Locale: 'lo'
        },
        params: {
          is_only_count: true,
          start_date: this.start_date,
          end_date: this.end_date,
          query: this.searchItem
        }
      }).then((response) => {
        this.count = response.data.count || 0;
      }).catch(() => {
      });
    },
  },
  created() {
    this.FetchData();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.defaultStatusClass {
  background: red;
  padding: 2px 8px;
  width: max-content;
  color: #ffffff;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: 1px;
}

.contacted-class {
  background: #12CBC4;
}

.pending-class {
  background: rgba(249, 202, 36, 0.6);
}

.accepted-class {
  background: #0652DD;
}

.rejected-class {
  background: #e21312;
}

.approved-class {
  background: #A3CB38;
}

.resolved-class {
  background: #009432;
}

</style>
